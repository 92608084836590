import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import ResourcesComponent from "../components/resources"

import "../assets/css/main.css"

const IndexPage = () => (
    <Layout>
        <StaticQuery
            query={graphql`
        query {
          allStrapiResource {
            edges {
              node {
                strapiId
                slug
                title
              }
            }
          }
        }
      `}
            render={data => (
                <div className="uk-section">
                    <div className="uk-container uk-container-large">
                        <h1>Teach Starter Resources</h1>
                        <ResourcesComponent resources={data.allStrapiResource.edges} />
                    </div>
                </div>
            )}
        />
    </Layout>
)

export default IndexPage