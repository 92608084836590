import React from "react"
import { Link } from "gatsby"

const Card = ({ resource }) => {
    return (
        <Link to={`/resource/${resource.node.slug}`} className="uk-link-reset">
            <div className="uk-card uk-card-muted">
                <div className="uk-card-body">
                    <p id="title" className="uk-text-large">
                        {resource.node.title}
                    </p>
                    <p>https://netlify-gatsby-test.teachstarter.com/resource/{resource.node.slug}</p>
                </div>
            </div>
        </Link>
    )
}

export default Card