import React from "react"
import Card from "./card"

const Resources = ({ resources }) => {
    const leftResourcesCount = Math.ceil(resources.length / 5)
    const leftResources = resources.slice(0, leftResourcesCount)
    const rightResources = resources.slice(leftResourcesCount, resources.length)

    return (
        <div>
            <div className="uk-child-width-1-2" data-uk-grid>
                <div>
                    {leftResources.map((resource, i) => {
                        return (
                            <Card resource={resource} key={`resource__${resource.node.id}`} />
                        )
                    })}
                </div>
                <div>
                    <div className="uk-child-width-1-2@m uk-grid-match" data-uk-grid>
                        {rightResources.map((resource, i) => {
                            return (
                                <Card resource={resource} key={`resource__${resource.node.id}`} />
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Resources